<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Reports
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Offline Hotspots Report
            <v-spacer></v-spacer>
            <v-text-field
              v-model="offlineHotspotSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          
          <v-btn dark color="green" @click="getOfflineReport()">
            Execute<v-icon dark> mdi-eye </v-icon>
          </v-btn> 
          <v-btn dark color="error" @click="offlineReportData = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          > 
          <vue-json-to-csv
            csvTitle="offlineHotspots"
            :json-data="this.offlineHotspotExportData"
            @success="handleOfflineHotspotExportSuccess()"
            @error="handleOfflineHotspotExportError()"
          >
            <v-btn dark color="green" @click="exportOfflineHotspot()">export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn>
          </vue-json-to-csv>
          <div class="pa-2">
            <v-progress-linear
              v-show="offlineHotspotProgressBar"
              indeterminate
              color="green"
            ></v-progress-linear>
            <v-data-table
              :headers="offlineHotspotReportHeaders"
              :items="offlineReportData"
              item-key="id"
              class="elevation-1"
              ref="offlineHotspotReportTable"
              :search="offlineHotspotSearch"
              :custom-filter="filterOfflineHotspot"
              @current-items="getFilteredOfflineHotspot"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15, -1]}"
              :options="offlineHotspotPageOptions"
              @update:items-per-page="showLoading"
            >
              <template v-slot:item.name="{ item }">
                <a :href="`/user/${item.userId}`" target="_blank"> {{item.name}} </a>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Under Performing Hotspots Report 
            <v-spacer></v-spacer>
            <v-text-field
              v-model="underPerformingHotspotSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row class="pa-6">
            <v-text-field label="amount HNT" type="number" v-model="amount"  @input="customkeyup"></v-text-field>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date1Formatted"
                  label="Start date"
                  hint="Start date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :format="format_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                :min="mindat"
                :max="nowDate"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2Formatted"
                  label="End date"
                  hint="End date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :format="format_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                :max="nowDate"
                @input="menu2 = false"
              ></v-date-picker> </v-menu
          ></v-row>
          <v-btn dark color="green" @click="getLowerthan(amount,date1,date2)"
            >Execute<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="requestLog2 = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >
          <vue-json-to-csv
            csvTitle="UnderPerformingHotspots"
            :json-data="this.underPerformingHotspotExportData"
            @success="handleUnderPerformingHotspotExportSuccess()"
            @error="handleUnderPerformingHotspotExportError()"
          >
            <v-btn dark color="green" @click="exportUnderPerformingHotspot()">export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn>
          </vue-json-to-csv>
          <div class="pa-2">
            <v-data-table
              :headers="headers2"
              :items="requestLog2"
              item-key="id"
              class="elevation-1"
              :search="underPerformingHotspotSearch"
              :custom-filter="filterUnderPerformingHotspot"
              @current-items="getFilteredUnderPerformingHotspot"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15, -1]}"
              :options="underPerformingHotspotPageOptions"
              @update:items-per-page="showLoading"
            >
              <template v-slot:item.append> </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Per User Earnings Report
            <v-spacer></v-spacer>
            <v-text-field
              v-model="perUserEarningSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row class="pa-6">
              <v-autocomplete
                :items="allUserOptionArr"
                :item-text="getItemText"
                label="Username - Email"
                v-model="SelectedUser"
                :rules="emailRules"
              >
              </v-autocomplete>
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date3Formatted"
                    label="Start date"
                    hint="Start date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date3"
                  :min="mindat"
                  :max="nowDate"
                  @change="sendRange1"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu4"
                v-model="menu4"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date4Formatted"
                    label="End date"
                    hint="End date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date4"
                  :min="date3"
                  :max="nowDate"
                  @input="menu4 = false"
                ></v-date-picker> 
              </v-menu>
            </v-row>
            <v-btn dark color="green" @click="getUserearn(SelectedUser)">
              Execute<v-icon dark> mdi-eye </v-icon>
            </v-btn>
            <v-btn dark color="error" @click="requestLog3 = []">
              Clean<v-icon dark> mdi-delete-sweep-outline </v-icon>
            </v-btn>
            <vue-json-to-csv :json-data="this.requestLog3" :csvTitle="`perUsersEarningsReport-[${this.date3Formatted}]-[${this.date4Formatted}]`">
              <v-btn dark color="green">
                Export<v-icon dark> mdi-microsoft-excel </v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-form>
          <div class="pa-2">
            <v-progress-linear
              v-show="userEarningProgressBar"
              indeterminate
              color="green"
            ></v-progress-linear>
            <v-data-table
              :headers="headers3"
              :items="requestLog3"
              item-key="id"
              class="elevation-1"
              :search="perUserEarningSearch"
              :custom-filter="filterOnlyCapsText"
              @update:items-per-page="showLoading"
            >
              <template v-slot:item.append> </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>



      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            JAG Mining Report
            <v-spacer></v-spacer>
            
          </v-card-title>
          <v-alert dismissible v-if="jagMiningReportAPI === false" type="warning">
            API call failed please try again...
          </v-alert>
          <v-form ref="form" class="mx-2" lazy-validation>
          <v-row class="pa-6">
      
            
            <v-menu
              ref="menu5"
              v-model="menu5"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date5Formatted"
                  label="Start date"
                  hint="Start date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="nameRules"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date5"
                :min="mindat"
                :max="nowDate"
                 @change="sendRange3"
                @input="menu5 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menu6"
              v-model="menu6"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date6Formatted"
                  label="End date"
                  hint="End date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="nameRules"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date6"
                :min="date5"
                :max="nowDate"
                @input="menu6 = false"
              ></v-date-picker> </v-menu
          ></v-row>
          <v-btn dark color="green" @click="getJagMiningReport();"
            >Execute 1<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="totalJagMiningReport =[]"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >
          
          <vue-json-to-csv :json-data="this.totalJagMiningReport" csvTitle="Total Investment Mined Report"
            ><v-btn dark color="green"
              >Export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn
            >
          </vue-json-to-csv></v-form>
          <div class="pa-2">
                  <v-data-table
                    :headers="headers4"
                    :items="totalJagMiningReport"
                    :items-per-page="5"
                    class="elevation-1"
                    :custom-sort="customSort"
                    @update:items-per-page="showLoading"
                  >
                    <template v-slot:item.month="{ item }">
                      {{item.month}}
                    </template>
                    <template v-slot:item.currentMonthHotspot="{ item }">
                      {{changeNumberFormat(item.currentMonthHotspot)}}
                    </template>

                    <template v-slot:item.currentMonthOnlineHotspot="{ item }">
                      {{changeNumberFormat(item.currentMonthOnlineHotspot)}}
                    </template>

                    <template v-slot:item.HNTMined="{ item }">
                      {{changeNumberFormat(item.HNTMined)}}
                    </template>
                     <template v-slot:item.HNTValue="{ item }">
                      {{item.HNTValue}}
                    </template>
                  </v-data-table>
          </div>
        </v-card>
      </v-col>


      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            New User Report
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchNewUser"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row class="pa-6">
              <v-menu
                ref="menu7"
                v-model="menu7"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date7Formatted"
                    label="Start date"
                    hint="Start date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :rules="nameRules"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date7"
                  :min="mindat"
                  :max="nowDate"
                  @change="sendRange4"
                  @input="menu7 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu8"
                v-model="menu8"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date8Formatted"
                    label="End date"
                    hint="End date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :rules="nameRules"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date8"
                  :min="date7"
                  :max="nowDate"
                  @input="menu8 = false"
                ></v-date-picker> 
              </v-menu>
            </v-row>
            <v-btn dark color="green" @click="getNewUsers()"
              >Execute<v-icon dark> mdi-eye </v-icon></v-btn
            >
            <v-btn dark color="error" @click="requestLog5 = []"
              >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
            >

            <vue-json-to-csv
              csvTitle="newUsersReport"
              :json-data="this.newUserExportData"
              @success="handleNewUserExportSuccess()"
              @error="handleNewUserExportError()"
            >
              <v-btn dark color="green" @click="exportNewUser()">export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn>
            </vue-json-to-csv>
          </v-form>
          <div class="pa-2">
            <v-progress-linear
              v-show="newUserProgressBar"
              indeterminate
              color="green"
            ></v-progress-linear>
            <v-data-table
              :headers="headers5"
              :items="requestLog5"
              item-key="id"
              class="elevation-1"
              :search="searchNewUser"
              :custom-filter="filterNewUsers"
              @current-items="getFilteredNewUsers"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15, -1]}"
              :options="newUserPageOptions"
              @update:items-per-page="showLoading"
            >
              <template v-slot:item.append> </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="openUser(item.ID)">Show</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>


      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Wallet Summary
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWalletSummary"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-row class="pa-6">
            <v-menu
              ref="menu9"
              v-model="menu9"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date9Formatted"
                  label="Start date"
                  hint="Start date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date9"
                :min="mindat"
                :max="nowDate"
                 @change="sendRange5"
                @input="menu9 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menu10"
              v-model="menu10"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date10Formatted"
                  label="End date"
                  hint="End date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date10"
                :min="date9"
                :max="nowDate"
                @input="menu10 = false"
              ></v-date-picker> </v-menu
          ></v-row>

          <v-btn dark color="green" @click="getWalletSummary()"
            >Execute<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="requestLog7 = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >

          <vue-json-to-csv
            csvTitle="walletSummary"
            :json-data="this.requestLog7"
          >
            <v-btn dark color="green">export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn>
          </vue-json-to-csv>

           <v-data-table
              :headers="headers6"
              :items="requestLog7"
              item-key="id"
              class="elevation-1"
              :search="searchWalletSummary"
            >
            </v-data-table>
          </v-card>
        </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Hotspot Device Type
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchHotspotDeviceType"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-btn dark color="green" @click="fetchHotspotDeviceType()"
            >Execute<v-icon dark> mdi-eye </v-icon></v-btn
          >
          <v-btn dark color="error" @click="hotspotDeviceTypeData = []"
            >Clean<v-icon dark> mdi-delete-sweep-outline </v-icon></v-btn
          >
          <vue-json-to-csv
            csvTitle="hotspotDeviceType"
            :json-data="this.hotspotDeviceTypeExportData"
            :labels="{
              hotspotName: { title: 'Hotspot Name' },
              hotspotManufacturer: { title: 'Hotspot Manufacturer' },
              status: { title: 'Status' },
              userAccountEmailAssociated: { title: 'User Account Email Associated' },
            }"
            @success="handleHotspotDeviceTypeExportSuccess()"
            @error="handleHotspotDeviceTypeExportError()"
          >
            <v-btn dark color="green" @click="exportHotspotDeviceType()">export<v-icon dark> mdi-microsoft-excel </v-icon></v-btn>
          </vue-json-to-csv>
          <div class="pa-2">
            <v-progress-linear
              v-show="hotspotDeviceTypeProgressBar"
              indeterminate
              color="green"
            ></v-progress-linear>
            <v-data-table
              :headers="hotspotDeviceTypeHeader"
              :items="hotspotDeviceTypeData"
              item-key="id"
              class="elevation-1"
              :search="searchHotspotDeviceType"
              :custom-filter="filterHotspotDeviceType"
              @current-items="getFilteredHotspotDeviceType"
              :footer-props="{'itemsPerPageOptions':[5, 10, 15, -1]}"
              :options="hotspotDeviceTypePageOptions"
              @update:items-per-page="showLoading"
            >
              <template v-slot:item.hotspotName="{ item }">
                <td>
                  {{
                    item.hotspotName != undefined
                      ? item.hotspotName
                      : "N/A"
                  }}
                </td>
              </template>
              
            </v-data-table>
          </div>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<style scoped>
/*FULL-PAGE_LOADER*/
.full-page-loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: linear-gradient(
    61deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
#wifi-loader {
  --background: #62abff;
  --front-color: #184cf8;
  --back-color: #c3c8de;
  --text-color: #414856;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}
#wifi-loader svg circle.back {
  stroke: var(--back-color);
}
#wifi-loader svg circle.front {
  stroke: var(--front-color);
}
#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}
#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}
#wifi-loader svg.circle-outer circle.back {
  -webkit-animation: circle-outer 1.8s ease infinite 0.3s;
  animation: circle-outer 1.8s ease infinite 0.3s;
}
#wifi-loader svg.circle-outer circle.front {
  -webkit-animation: circle-outer 1.8s ease infinite 0.15s;
  animation: circle-outer 1.8s ease infinite 0.15s;
}
#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}
#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}
#wifi-loader svg.circle-middle circle.back {
  -webkit-animation: circle-middle 1.8s ease infinite 0.25s;
  animation: circle-middle 1.8s ease infinite 0.25s;
}
#wifi-loader svg.circle-middle circle.front {
  -webkit-animation: circle-middle 1.8s ease infinite 0.1s;
  animation: circle-middle 1.8s ease infinite 0.1s;
}
#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}
#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}
#wifi-loader svg.circle-inner circle.back {
  -webkit-animation: circle-inner 1.8s ease infinite 0.2s;
  animation: circle-inner 1.8s ease infinite 0.2s;
}
#wifi-loader svg.circle-inner circle.front {
  -webkit-animation: circle-inner 1.8s ease infinite 0.05s;
  animation: circle-inner 1.8s ease infinite 0.05s;
}
#wifi-loader .text {
  position: absolute;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}
#wifi-loader .text::before,
#wifi-loader .text::after {
  content: attr(data-text);
}
#wifi-loader .text::before {
  color: var(--text-color);
}
#wifi-loader .text::after {
  color: var(--front-color);
  -webkit-animation: text-animation 3.6s ease infinite;
  animation: text-animation 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@-webkit-keyframes circle-outer {
  0% {
    stroke-dashoffset: 25;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 301;
  }
  80% {
    stroke-dashoffset: 276;
  }
  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-outer {
  0% {
    stroke-dashoffset: 25;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 301;
  }
  80% {
    stroke-dashoffset: 276;
  }
  100% {
    stroke-dashoffset: 276;
  }
}
@-webkit-keyframes circle-middle {
  0% {
    stroke-dashoffset: 17;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 204;
  }
  80% {
    stroke-dashoffset: 187;
  }
  100% {
    stroke-dashoffset: 187;
  }
}
@keyframes circle-middle {
  0% {
    stroke-dashoffset: 17;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 204;
  }
  80% {
    stroke-dashoffset: 187;
  }
  100% {
    stroke-dashoffset: 187;
  }
}
@-webkit-keyframes circle-inner {
  0% {
    stroke-dashoffset: 9;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 106;
  }
  80% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 97;
  }
}
@keyframes circle-inner {
  0% {
    stroke-dashoffset: 9;
  }
  25% {
    stroke-dashoffset: 0;
  }
  65% {
    stroke-dashoffset: 106;
  }
  80% {
    stroke-dashoffset: 97;
  }
  100% {
    stroke-dashoffset: 97;
  }
}
@-webkit-keyframes text-animation {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  50% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
}
@keyframes text-animation {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  50% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
}
</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL } from '../constants';
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      offlineHotspotPageOptions: {
        itemsPerPage: 10
      },
      underPerformingHotspotPageOptions: {
        itemsPerPage: 10
      },
      hotspotDeviceTypePageOptions: {
        itemsPerPage: 10
      },
      newUserPageOptions: {
        itemsPerPage: 10
      },
      offlineHotspotExportData : [],
      underPerformingHotspotExportData : [],
      newUserExportData : [],
      hotspotDeviceTypeExportData : [],
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      months : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      message: "",
      amount: 0,
      todaydate: new Date(),
      nowDate: new Date().toISOString().slice(0, 10),
      dialog: false,
      finalexport:[],
      hotspotDetails: [],
      hotspotTotal: [],
      tempAmount:0,
      offlineHotspotProgressBar: false,
      userEarningProgressBar: false,
      newUserProgressBar: false,
      hotspotDeviceTypeProgressBar: false,
      SelectedUser:"",
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      search: "",
      offlineHotspotSearch:"",
      underPerformingHotspotSearch: "",
      perUserEarningSearch: "",
      searchNewUser: "",
      searchWalletSummary:"",
      searchHotspotDeviceType:"",
      allUser:[],
      allUserOptionArr:[],
      withdrawstotal: {},
      uid: "",
      menu2: false,
      menu1:false,
      date1:"",
      date1Formatted: "",
      date2:"",
      date2Formatted: "",
      menu3: false,
      menu4:false,
      menu5: false,
      menu6:false,
      menu7: false,
      menu8:false,
      menu9: false,
      menu10:false,
      date3: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date3Formatted: moment().subtract(1, 'month').format('MM-DD-YYYY'),
      date4: moment().format('YYYY-MM-DD'),
      date4Formatted: moment().format('MM-DD-YYYY'),
      date5: moment().subtract(3, 'month').format('YYYY-MM-DD'),
      date5Formatted: moment().subtract(3, 'month').format('MM-DD-YYYY'),
      date6: moment().format('YYYY-MM-DD'),
      date6Formatted: moment().format('MM-DD-YYYY'),
      date7: moment().subtract(2, 'month').format('YYYY-MM-DD'),
      date7Formatted: moment().subtract(2, 'month').format('MM-DD-YYYY'),
      date8: moment().format('YYYY-MM-DD'),
      date8Formatted: moment().format('MM-DD-YYYY'),
      date9: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date9Formatted: moment().subtract(1, 'month').format('MM-DD-YYYY'),
      date10: moment().format('YYYY-MM-DD'),
      date10Formatted: moment().format('MM-DD-YYYY'),
      accountAdded: [],
      oracleprice: null,
      installation: "",
      requestLog: [],
      requestLog2: [],
      requestLog3: [],
      requestLog4: [],
      requestLog5: [],
      requestLog6: [],
      requestLog7: [],
      hotspotDeviceTypeData: [],
      hotspotDeviceMaker: [],
      investcalc:[],
      maxearn: 0,
      FirstTier:70,
      SecondTier:20,
      ThirdTier:5,
      tier: null,
      indexcount:[],
      nameRules: [(v) => !!v || "Required"],
      emailRules: [(v) => !!v || "Email Required"],
      mindat: '',
      userInvestment:[],
      currentMonthOnlineHotspot:[],
      totalJagMiningReport:[],
      perUserFailedApiCalls:[],
      jagMiningReportAPI:true,
      offlineReportData: [],
      perUserEarningReportTitle: '',

    };
  },
  watch: {
    date1 (val) {
      this.date1Formatted = this.format_date(val);
      //this.date1 = this.format_date(this.date1)
    },
    date2 (val) {
      this.date2Formatted = this.format_date(val);
      //this.date2 = this.format_date(this.date2)
    },
    date3 (val) {
      this.date3Formatted = this.format_date(val);
      //this.date3 = this.format_date(this.date3)
    },
    date4 (val) {
      this.date4Formatted = this.format_date(val);
      //this.date4 = this.format_date(this.date4)
    },
    date5 (val) {
      this.date5Formatted = this.format_date(val);
      //this.date5 = this.format_date(this.date5)
    },
    date6 (val) {
      this.date6Formatted = this.format_date(val);
      //this.date6 = this.format_date(this.date6)
    },
    date7 (val) {
      this.date7Formatted = this.format_date(val);
      //this.date7 = this.format_date(this.date7)
    },
    date8 (val) {
      this.date8Formatted = this.format_date(val);
      //this.date8 = this.format_date(this.date8)
    },
    date9 (val) {
      this.date9Formatted = this.format_date(val);
      //this.date9 = this.format_date(this.date9)
    },
    date10 (val) {
      this.date10Formatted = this.format_date(val);
      //this.date10 = this.format_date(this.date10)
    },
    // requestLog3() {
    //   this.perUserEarningReportTitle = `perUsersEarningsReport-${this.date3Formatted}-${this.date4Formatted}`
    // }
  },
  computed: {
    offlineHotspotReportHeaders() {
      return [
        { text: "Hotspot Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Status", value: "status" },
        { text: "Email", value: "user" },
        { text: "Phone Number", value: "mobile" },
        { text: "Hoster Name", value: "hostname" },
        { text: "Total earning", value: "total" },
        { text: "Offline Date", value: "offline_date"}
      ];
    },
    headers2() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Total Rewards", value: "total"},
        { text: "Hotspot Primary Host Name", value: "hostname" },
        { text: "Hotspot Primary Host Email", value: "user" },
        { text: "Hotspot Primary Host Phone Number", value: "mobile" },
      ];
    },
    headers3() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Total Rewards for the Hotspot", value: "total" },
        { text: "Hotspot Primary Host Name", value: "hostname" },
        { text: "Hotspot Primary Host Email", value: "user" },
        { text: "Hotspot Primary Host Phone Number", value: "mobile" },
        { text: "Host Name’s Relationship to Hotspot", value: "relationship" },
        { text: "Online Date", value: "onlineDate" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
      ];
    },
    headers4() {
      
      return[
          {text: 'Month',value:'month'},
          { text: 'Total JAG Owned Hotspots', value: 'currentMonthHotspot' },
          { text: 'Total JAG Online Hotspots', value: 'currentMonthOnlineHotspot' },
          { text: 'Total HNT Mined by JAG (Monthly)', value: 'HNTMined' },
          { text: 'HNT Monthly Average Price (USD)', value: 'HNTValue' }
        ]
    },
    headers5() {
    
      return [
        { text: "Hotspot Primary Host Name", value: "hostname" },
        { text: "Hotspot Primary Host Email", value: "user" },
        { text: "Hotspot Primary Host Phone Number", value: "mobile" },
        { text: "Contract Signed value", value: "signed" },
        { text: "Date Contract was signed", value: "createdsign" },
        { text: "User creation date", value: "createDate" },
        { text: "Hotspot name", value: "name" },
        { text: "Hotspot address", value: "address" }

      ];
    },
    headers6() {  
    
      return [
        { text: "Date", value: "Date" },
        { text: "HNT", value: "HNT" },
        { text: "HNT Closing Price", value: "HNTClosingPrice" },
        { text: "USD", value: "USD" },
        { text: "Online Hotspots", value: "ONLINE" },   
      ];
    },
    hotspotDeviceTypeHeader() {
      return [
        { text: "Hotspot Name", value: "hotspotName" },
        { text: "Hotspot Manufacturer", value: "hotspotManufacturer" },
        { text: "Status", value: "status" },
        { text: "User Account Email Associated", value: "userAccountEmailAssociated" }

      ];
    },
    monthfirstDay() {
      return new Date(
        this.todaydate.getFullYear(),
        this.todaydate.getMonth() + 1,
        1
      );
    }
  },

  beforeMount() {
    //this.getRequest();
   // this.getuid();
    
    this.getOracleValue();
    this.getAllUser()
    this.getAddress();
  },

  methods: {
    sendRange(e){
      if(this.date1 > this.date2) {
        this.date2 = null
      }
    },
    sendRange1(e){
      if(this.date3 > this.date4) {
        this.date4 = null
      }
    },
    sendRange3(e){
      if(this.date5 > this.date6) {
        this.date6 = null
      }
    },
    sendRange4(e){
      if(this.date7 > this.date8) {
        this.date8 = null
      }
    },
    sendRange5(e){
      if(this.date9 > this.date10) {
        this.date10 = null
      }
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('MM-DD-YYYY')
      }
    },
    customkeyup (e){
      if (e == '') {
        this.$nextTick(() => {
        if(this.amount == '' && this.tempAmount == 0){
          this.amount = 0
        }else if(this.amount.length == 0 && this.tempAmount.length == 1){
          this.tempAmount = 0
           this.amount = 0
        }else if( this.tempAmount != 0){
             this.amount = this.tempAmount.replace(/^0+/, '')
          }
        })        
      }else{
        this.$nextTick(() => {
          this.amount = e.replace(/^0+/, '');
        })
        this.tempAmount = e;
      }
    },
    showLoading() {
      this.$root.isLoading = true;
      setTimeout(()=>{
        this.$root.isLoading = false;
      },2000);
    },
    getFilteredOfflineHotspot(e){
      this.offlineHotspotExportData = e;
    },
    filterOfflineHotspot(value, search, item) {
      value = value.toString().toLocaleLowerCase();
      search = search.toString().toLocaleLowerCase();
      return value.indexOf(search) !== -1;
    },
    exportOfflineHotspot() {
      this.offlineHotspotPageOptions.itemsPerPage = -1;
    },
    handleOfflineHotspotExportSuccess() {
      this.offlineHotspotPageOptions.itemsPerPage = 10;
    },
    handleOfflineHotspotExportError() {
      this.offlineHotspotPageOptions.itemsPerPage = 10;
    },
    //Under performing hotspot report start
    getFilteredUnderPerformingHotspot(e){
      this.underPerformingHotspotExportData = e;
    },
    filterUnderPerformingHotspot(value, search, item) {

      if(isNaN(value)){
          value = value.toString().toLocaleLowerCase();
         search = search.toString().toLocaleLowerCase();
         return value.indexOf(search) !== -1;
      }else{
        return value == search
      }
    },
    exportUnderPerformingHotspot() {
      this.underPerformingHotspotPageOptions.itemsPerPage = -1;
    },
    handleUnderPerformingHotspotExportSuccess() {
      this.underPerformingHotspotPageOptions.itemsPerPage = 10;
    },
    handleUnderPerformingHotspotExportError() {
      this.underPerformingHotspotPageOptions.itemsPerPage = 10;
    },
    //Under performing hotspot report end
    filterHotspotDeviceType(value, search, item) {
      value = value.toString().toLocaleLowerCase();
      search = search.toString().toLocaleLowerCase();
      return value.indexOf(search) !== -1;
    },
    getFilteredHotspotDeviceType(e){
      this.hotspotDeviceTypeExportData = e;
    },
    exportHotspotDeviceType() {
      this.hotspotDeviceTypePageOptions.itemsPerPage = -1;
    },
    handleHotspotDeviceTypeExportSuccess() {
      this.hotspotDeviceTypePageOptions.itemsPerPage = 10;
    },
    handleHotspotDeviceTypeExportError() {
      this.hotspotDeviceTypePageOptions.itemsPerPage = 10;
    },
    //new user export functionality start
    filterNewUsers(value, search, item) {
      value = value.toString().toLocaleLowerCase();
      search = search.toString().toLocaleLowerCase();
      return value.indexOf(search) !== -1;
    },
    getFilteredNewUsers(e){
      this.newUserExportData = e;
    },
    exportNewUser() {
      this.newUserPageOptions.itemsPerPage = -1;
    },
    handleNewUserExportSuccess() {
      this.newUserPageOptions.itemsPerPage = 10;
    },
    handleNewUserExportError() {
      this.newUserPageOptions.itemsPerPage = 10;
    },
    //new user export functionality end
    formatHotspotName(hotspotName) {
      let hotspot_name = hotspotName.replaceAll('-',' ');
      let hotspot_name_caps = hotspot_name.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return hotspot_name_caps;
    },
    getItemText(item) {
      return `${item.name} - ${item.email}`;
    },
    getAllUser(){
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.allUser = resp.data;
          //Sorting name in ascending order
          resp.data.sort(function (a, b) {
              if (a.name < b.name) {
                  return -1;
              }
              if (a.name > b.name) {
                  return 1;
              }
              return 0;
          });
          this.allUserOptionArr= JSON.parse(JSON.stringify(resp.data));
          var obj = {'name': 'All', 'email': 'User'};
          this.allUserOptionArr.unshift(obj);
 
        });
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    sendRequest(amount, message) {
      console.log(this.message);
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.dialog = false;
      this.getRequest();
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/account`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
           this.accountInfo();
        });
    },
    getTotal(address, install, ind) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${install.slice(
            0,
            10
          )}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          if (resp.data.data) {
            this.requestLog[ind].total = resp.data.data.total;
          } else {
            this.requestLog[ind].total = 0;
          }
        })
        .catch((resp) => {
          this.requestLog[ind].total = 0;
        });
    },
    getOfflineDetail(address, install, ind) {
    axios
      .get(
        `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=-200%20day&bucket=day`,
        {
          headers: { accept: "application/json" },
        }
      )
      .then((resp) => {
        console.log('inside getOfflineDetail', resp);
        if (resp.data.data) {
            for(let i = 0;  i < resp.data.data.length; i++){
             if(resp.data.data[i].sum != 0 && resp.data.data[i].total != 0){
                this.requestLog[ind].offline_date = moment(resp.data.data[i].timestamp).format('YYYY-MM-DD');
             }
            }

        } else {
          this.requestLog[ind].offline_date = 'Not Available';
        }

        if(this.requestLog[ind].offline_date == undefined) this.requestLog[ind].offline_date = 'Not Available';
        console.log('this.requestLog', this.requestLog);
      })
      .catch((resp) => {
          this.requestLog[ind].offline_date = 'Not Available';
      });
  },
  getOfflineorg() {
      console.log('this.accountAdded', this.accountAdded);
      this.requestLog = [];
      var x = 0;
      for (var i = 0; i < this.accountAdded.length; i++) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/accounts/${this.accountAdded[i].address}/hotspots`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            resp.data.data.forEach((element, index) => {
              if (element.status.online == "offline") {
                var data = {};
                data.status = element.status.online;
                data.name = element.name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                      return letter.toUpperCase();
                                  });
                data.address = element.address;
                data.location =
                  element.geocode.short_street +
                  " - " +
                  element.geocode.short_state +
                  " - " +
                  element.geocode.short_country +
                  " - " +
                  element.geocode.short_city;
                data.wallet = element.owner
                data.user = "no owner";
                data.mobile = "";
                data.hostname = "";
                data.total = 0;
                this.getTotal(element.address, element.timestamp_added, x);
                this.getOwnerHotspot(element.address, x);
                this.requestLog.push(data);
                this.getOfflineDetail(element.address, element.timestamp_added, x);
                x += 1;
              }
            });
          });
      }
  },
  getOfflineReport() {
    this.offlineReportData = [];
    this.offlineHotspotProgressBar = true;
    this.$root.isLoading = true;
    axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/offline/hotspots`, {
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      this.offlineHotspotProgressBar = false;
      var offlineHotspotResponse = response.data;
      if(offlineHotspotResponse.length > 0) {
        offlineHotspotResponse.forEach((offlineHotspot) => {
          let data = {};
          data.userId = offlineHotspot.userId;
          data.name = this.formatHotspotName(offlineHotspot.hotspotName);
          data.address = offlineHotspot.hotspotAddress;
          data.location = ((offlineHotspot.short_street) ? offlineHotspot.short_street : "" )+"-"+((offlineHotspot.short_state) ? offlineHotspot.short_state : "")+"-"+((offlineHotspot.short_country) ? offlineHotspot.short_country : "")+"-"+((offlineHotspot.short_city) ? offlineHotspot.short_city :"");
          data.wallet = offlineHotspot.hotspotOwner;
          data.status = offlineHotspot.hotspotStatus;
          data.user = (offlineHotspot.email) ? offlineHotspot.email : 'N/A';
          data.mobile = (offlineHotspot.Mobile) ? offlineHotspot.Mobile : 'N/A';
          data.hostname = (offlineHotspot.FirstName || offlineHotspot.LastName) ? offlineHotspot.FirstName+" "+offlineHotspot.LastName : (offlineHotspot.name) ? offlineHotspot.name : 'N/A' ;
          data.total = offlineHotspot.hotspotEarning.toFixed(2);
          data.offline_date = (offlineHotspot.hotspotOfflineDate) ? moment(offlineHotspot.hotspotOfflineDate).format('MM-DD-YYYY') : 'N/A';
          
          this.offlineReportData.push(data);
        });
      }
      this.$root.isLoading = false;
    })
    .catch((error) => {
      console.log(error);
      this.offlineHotspotProgressBar = false;
      this.$root.isLoading = false;
    });
  },
  getOfflineOld() {
    var accountAdded = [];
    this.y = 0;
    for (var u = 0; u < this.allUser.length; u++) 
    {
      axios.get(`${API_BASE_URL}/user/hotspot/${this.allUser[u].firebaseID}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        accountAdded = resp.data;
        this.requestLog = [];
        for (var i = 0; i < accountAdded.length; i++) 
        {
          if(accountAdded[i].Haddress != 'empty')
          {
            var today = new Date();
            var end = accountAdded[i].removeDate? new Date(accountAdded[i].removeDate.slice(0,10)):today;
            //compare only date
            today.setHours(0,0,0,0)
            //get hotspot whose are currently assigned(Empty Remove Date means they are currently assigned)
            if(end >= today)
            {
              var userFireBaseId = accountAdded[i].userID; // Using firebase id to get exact user
              axios.get(`${API_HELIUM_URL}/v1/hotspots/${accountAdded[i].Haddress}`,{
                headers: {
                  accept: "application/json",
                },
              })
              .then((element) => {
                element = element.data.data;
                if (element.status.online == "offline") {
                  var data = {};
                  data.userId = this.getUserId(userFireBaseId);
                  data.status = element.status.online;
                  data.name = element.name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                      return letter.toUpperCase();
                                  });
                  data.address = element.address;
                  data.location =
                        element.geocode.short_street +
                        " - " +
                        element.geocode.short_state +
                        " - " +
                        element.geocode.short_country +
                        " - " +
                        element.geocode.short_city;
                  data.wallet = element.owner
                  data.user = "no owner";
                  data.mobile = "";
                  data.hostname = "";
                  data.total = 0;
                  this.getTotal(element.address, element.timestamp_added, this.y);
                  this.getOwnerHotspot(element.address, this.y);
                  this.requestLog.push(data);
                  this.y += 1;
                }
              });
            }
          }

        }
            
      })
      .catch((resp) => {});
    }
  },
  //function to fetch hotspot type report
  fetchHotspotDeviceType() {
    this.hotspotDeviceTypeData = [];
    this.hotspotDeviceTypeProgressBar = true;
    this.$root.isLoading = true;
    axios.get(`${API_HELIUM_NODE_JAG_URL}/vl/hotspot/type`, {
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      this.hotspotDeviceTypeProgressBar = false;
      let hotspotTypeDetails = response.data;
      if(hotspotTypeDetails.length > 0) {
        hotspotTypeDetails.forEach((hotspotTypeDetail) => {
          var data = {};
          data.hotspotName = (hotspotTypeDetail.hotspotName) ? this.formatHotspotName(hotspotTypeDetail.hotspotName) : 'N/A';
          data.hotspotManufacturer = hotspotTypeDetail.hotspotManufacturer;
          data.status = hotspotTypeDetail.status;
          data.userAccountEmailAssociated = (hotspotTypeDetail.userAccountEmailAssociated) ? hotspotTypeDetail.userAccountEmailAssociated : 'N/A';
          this.hotspotDeviceTypeData.push(data);
        });
      }
      this.$root.isLoading = false;
    })
    .catch((error) => {
      console.log(error);
      this.$root.isLoading = false;
      this.hotspotDeviceTypeProgressBar = false;
    });
  },
  getUserId(firebaseId){
      var userId = this.allUser.find(user => {
          return user.firebaseID == firebaseId
      });
      return userId.ID;
  },
  getOfflineDate(address){
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activity`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activitycursor=${resp.data.data.cursor}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            var unix_timestamp = resp.data.data[0].time
      var date = new Date(unix_timestamp * 1000);
      console.log(date)
      })
      })
      .catch((resp) => {

      })
  },
  async getUserearn(UserID) {
    this.$refs.form.validate();
    if(!this.date3 || !this.date4 || !this.SelectedUser){
      return false;
    }
    this.requestLog3 = [];
    this.$root.isLoading = true;
    this.userEarningProgressBar = true;
    if(UserID != 'All - User') {
      var useremail = UserID.split(" - ")
      var ind = this.allUser.find(obj => obj.email == useremail[1]);
      UserID = ind.firebaseID
    }
    else {
      UserID = 'All';
    }
    axios.post(`${API_HELIUM_NODE_JAG_URL}/v1/user/earnings`, {
      'userId': UserID, 
      'fromDate': this.date3, 
      'toDate': this.date4,
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      this.userEarningProgressBar = false;
      let userEarningArr = response.data;
      if(userEarningArr.length > 0) {
        userEarningArr.forEach((element) => {
          let data = {};
          data.status = element.hotspotStatus;
          data.name =  (element.hotspotName) ? this.formatHotspotName(element.hotspotName) : 'N/A';
          data.address = element.hotspotAddress;

          let locationArray = []
          element.short_street ? locationArray.push(element.short_street): ''
            element.short_state ? locationArray.push(element.short_state): ''
            element.short_country ? locationArray.push(element.short_country): ''
            element.short_city ? locationArray.push(element.short_city): ''

          data.location = locationArray.length > 0 ? locationArray.join(" - ") : 'N/A';
          data.wallet = element.hotspotOwner;
          data.total = (element.hotspotEarning) ? element.hotspotEarning.toFixed(2) : "0";
          data.hostname = (element.first_name || element.last_name) ? element.first_name+" "+element.last_name : element.name;
          data.user = element.email;
          data.mobile = (element.Mobile) ? element.Mobile : 'N/A';
          if(element.role === "0"){
            data.relationship="Primary Owner"
          }
          else if(element.role === "1"){
            data.relationship="Referral Owner"
          }
          else{
            data.relationship="Watcher"
          }
          data.onlineDate = (element.hotspotOnlineDate) ? moment(element.hotspotOnlineDate).format('MM-DD-YYYY') : "N/A";
          data.startDate = this.date3Formatted;
          data.endDate = this.date4Formatted;
          this.requestLog3.push(data);
        });
      }
      this.$root.isLoading = false;
    })
    .catch((error) => {
      console.log(error);
      this.$root.isLoading = false;
      this.userEarningProgressBar = false;
    });
  },
  async getUserearnOld(UserID) {
    this.$refs.form.validate();
    if(!this.date3 || !this.date4 || !this.SelectedUser){
      return false;
    }
    var url = `${API_BASE_URL}/users/hotspot`;
    if(UserID != 'All - User')
    {
      var useremail = UserID.split(" - ")
      var ind = this.allUser.find(obj => obj.email == useremail[1]);
      UserID = ind.firebaseID
      url = `${API_BASE_URL}/user/hotspot/${UserID}`;
    }
    var hotspotAll=[]
    axios
      .get(url, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        hotspotAll = resp.data;
        this.requestLog3 = [];
        var x = 0;
        var _this = this;
        for(var i = 0; i < hotspotAll.length; i++){
          (function(i){
            setTimeout(function(){
              console.log(i);
              axios.get(`${API_HELIUM_URL}/v1/hotspots/${hotspotAll[i].Haddress}`, {
                headers: { accept: "application/json" },
              })
              .then((element) => {
                var data = {};
                data.status = element.data.data.status.online;
                data.timestamp = element.data.data.status.timestamp;
                data.name = element.data.data.name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                      return letter.toUpperCase();
                                  });
                data.address = element.data.data.address;

                let locationArray = []
                element.data.data.geocode.short_street ? locationArray.push(element.data.data.geocode.short_street): ''
                element.data.data.geocode.short_state ? locationArray.push(element.data.data.geocode.short_state): ''
                element.data.data.geocode.short_country ? locationArray.push(element.data.data.geocode.short_country): ''
                element.data.data.geocode.short_city ? locationArray.push(element.data.data.geocode.short_city): ''


                data.location = locationArray.length > 0 ? locationArray.join(" - ") : 'N/A';
                data.wallet = element.data.data.owner;
                data.total = 0;
                data.user = "no owner";
                data.mobile = "";

                if(resp.data[x].role == 0)
                {
                  data.relationship="Primary Owner"
                }
                else if(resp.data[x].role ==1)
                {
                  data.relationship="Referral Owner"
                }
                else
                {
                  data.relationship="Watcher"
                }

                _this.getTotalUser(element.data.data.address, _this.date3,_this.date4, x);
                //_this.getUserHotspot(element.data.data.address, x);
                _this.getOwnerInfo(hotspotAll[x].userID, x);
                _this.requestLog3.push(data);
                x += 1;
              })
              .catch((error) => {
                if(error.response.status === 429)
                {
                  console.log(error.config.url);
                }
              });
            },1000 * i);
          }(i));
        }
      });
  },

  async getTotalUser(address, dateinst, endinst,ind) {
    axios
    .get(
      `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    )
    .then((resp) => {
      this.requestLog3[ind].total = resp.data.data.total;
    })
    .catch((resp) => {
      console.log(resp);
      this.requestLog3[ind].total = 0;
    });
  },
  async getOwnerInfo(firebaseId, index) {
    var head = {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    };
    axios.get(`${API_BASE_URL}/ownerinfo/${firebaseId}`, head)
    .then((result) => {
      if (result.data[0]) {
        this.requestLog3[index].hostname = result.data[0].name;
        this.requestLog3[index].user = result.data[0].email;
        this.requestLog3[index].mobile = result.data[0].Mobile;
      } else {
        this.requestLog3[index].user = "no owner";
      }
    });
  },
  async getOwnerHotspotLower(address, ind) {
    var head = {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    };
    axios
      .get(`${API_BASE_URL}/allowner/${address}`, head)
      .then((result) => {
        if (result.data[0]) {
          this.requestLog2[ind].hostname = (result.data[0].name) ? result.data[0].name : 'N/A';
          this.requestLog2[ind].user = (result.data[0].email) ? result.data[0].email : 'N/A';
          this.requestLog2[ind].mobile = (result.data[0].Mobile) ? result.data[0].Mobile : 'N/A';
        } else {
          this.requestLog2[ind].user = "no owner";
        }
      });
  },
  async getOwnerHotspot(address, ind) {
    var head = {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    };
   return axios
      .get(`${API_BASE_URL}/allowner/${address}`, head)
      .then((result) => {
        if (result.data[0]) {
          if(address == '11fA8QLJuuKdCgTip3e5uxprNBuLK6VtyypGhLtvzrnN4hMvWCz'){
            console.log("ind = ",ind)
            console.log('here', result.data[0]);
          }
            return {
              hostname: result.data[0].name,
              user: result.data[0].email,
              mobile: result.data[0].Mobile
            }
        } else {
          return {
              hostname: '',
              user: '',
              mobile: ''
            }
        }
      });
  },
  async getUserHotspot(address, ind) {

    var head = {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    };
    axios
      .get(`${API_BASE_URL}/allownerWithouRemoveDate/${address}`, head)
      .then((result) => {

        console.log("ind = ",ind)

        if (result.data[0]) {

          //for per user report
          if(this.requestLog3[ind]){
            this.requestLog3[ind].hostname = result.data[0].name;
            this.requestLog3[ind].user = result.data[0].email;
            this.requestLog3[ind].mobile = result.data[0].Mobile;
          }

          //for new report
          if(this.requestLog5[ind]){

              let dateCheck = moment(result.data[0].createDate.substring(0, 10)).format('YYYY-MM-DD');

              //check users of date range
              if(dateCheck >= this.date7 && dateCheck <= this.date8) {

                console.log("dateCheck = ",dateCheck,"this.date7 = ",this.date7,"this.date8 = ",this.date8)

                this.requestLog5[ind].hostname = result.data[0].name;
                this.requestLog5[ind].user = result.data[0].email;
                this.requestLog5[ind].mobile = result.data[0].Mobile?result.data[0].Mobile:"";

                this.requestLog5[ind].signed = result.data[0].signed == 1?"Yes":"No";
                this.requestLog5[ind].createdsign = moment(result.data[0].createdsign.substring(0, 10)).format('MM-DD-YYYY');
                this.requestLog5[ind].createDate = moment(result.data[0].createDate.substring(0, 10)).format('MM-DD-YYYY');

              }else{
                //remove "no woner" records from the display
                this.requestLog5.splice(ind, 1);
                delete this.requestLog5[ind];
              }
            }
          
        } else {

          //for new user report
          if(this.requestLog5[ind]){
            console.log("user = ",result.data[0])
            this.requestLog5.splice(ind, 1);
            delete this.requestLog5[ind];
          }

          //for per user report
          if(this.requestLog3[ind]){
            this.requestLog3.splice(ind, 1);
            delete this.requestLog3[ind];
          }
          
        }
      });
  },
  getLowerthan(HNT,start,end) {
    this.requestLog2 = [];
    this.$root.isLoading = true;
    var x = 0;
    for (var i = 0; i < this.accountAdded.length; i++) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/accounts/${this.accountAdded[i].address}/hotspots`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          resp.data.data.forEach((element, index) => {
            var data = {};
            let locationArray = []
                element.geocode.short_street ? locationArray.push(element.geocode.short_street): ''
                element.geocode.short_state ? locationArray.push(element.geocode.short_state): ''
                element.geocode.short_country ? locationArray.push(element.geocode.short_country): ''
                element.geocode.short_city ? locationArray.push(element.geocode.short_city): ''

            

            data.status = (element.status.online) ? element.status.online : 'N/A';
            data.name = this.formatHotspotName(element.name);
            data.address = element.address;
            data.location = locationArray.length > 0  ? locationArray.join(' - ') : 'N/A';
            data.wallet = (element.owner) ? element.owner : 'N/A';
            data.user = "no owner";
            data.mobile = "";
            data.hostname = "";
            data.total = 0.00;
            this.getbalance(element.address,start,end,100,x,HNT);
            this.getOwnerHotspotLower(element.address, x);
            this.requestLog2.push(data);
              x += 1;
          });
        });
    }
    this.$root.isLoading = false;
  },
  hotspotDet(address, start, today) {
    (this.dialog = false),
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDetails = resp.data;
          var [year, month, day] = this.hotspotDetails.data.timestamp_added
            .substring(0, 10)
            .split("-");
          this.installation = new Date(year, month, day);
          axios
            .get(
              `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                .toISOString()              
                .slice(0, 10)}`,
              {
                headers: { accept: "application/json" },
              }
            )
            .then((resp) => {
              this.hotspotTotal = resp.data;
              console.log('this.hotspotTotal', resp.data);
              this.mapurl = [
                "https://www.openstreetmap.org/export/embed.html?bbox=" +
                  this.hotspotDetails.data.lng +
                  "%2C" +
                  this.hotspotDetails.data.lat +
                  "%2C" +
                  this.hotspotDetails.data.lng +
                  "%2C" +
                  this.hotspotDetails.data.lat +
                  "&layer=mapnik&marker=" +
                  this.hotspotDetails.data.lat +
                  "%2C" +
                  this.hotspotDetails.data.lng,
              ].join("");
              this.maxearn = parseInt(
                ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
              );
            });
        });
  },
  openUser(userid) {
    this.$router.push({ name: "user", params: { id: userid } });
  },
  getbalance(address, dateinst, endinst, commission,ind,value) {
    console.log(address, dateinst, endinst)
    if (endinst == "null" || endinst == null) {
      endinst = "";
    }
    axios
      .get(
        `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((resp) => {
        this.requestLog2[ind].total = ((resp.data.data.total / 100) * commission).toFixed(2);
        if (this.requestLog2[ind].total <= value){
            this.indexcount.push(ind)
        }
      })
      .catch((resp) => {
        this.requestLog2[ind].total = 0.00;
      });
  },
  getRequest() {
    axios
      .get(`${API_BASE_URL}/users`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        var requestLog = resp.data;
        requestLog.forEach(
          (obj) => (obj["createDate"] = obj["createDate"].substring(0, 10))
        );
        axios
          .get(`${API_BASE_URL}/usersHotspot`, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            var hotspotOwn = resp.data;
            var i;

            for (i = 0; i < requestLog.length; i++) {
              if (
                requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
              ) {
                requestLog[i]["createdsign"] = "";
              }
              if (requestLog[i]["signed"] == "") {
                requestLog[i]["signed"] = "No";
              }
              if (requestLog[i]["signed"] == 0) {
                requestLog[i]["signed"] = "No";
              }
              if (requestLog[i]["signed"] == 1) {
                requestLog[i]["signed"] = "Yes";
              }
              if (
                hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                )
              ) {
                const result = hotspotOwn.find(
                  ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                );
                requestLog[i].assignHotspot = result.assignHotspot;
              } else {
                requestLog[i].assignHotspot = 0;
              }
            }
            this.requestLog = requestLog;
          });
      });
  },

  filterOnlyCapsText(value, search) {
    return (
      value != null &&
      search != null &&
      typeof value === "string" &&
      value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
    );
  },

  saveHotspot(address) {
    let payload = { haddress: address };
    let res = axios.put(
      `${API_BASE_URL}/hotspot/${this.uid}`,
      payload
    );
    let data = res.data;
  },
  getwithdrawal(uid) {

    axios
      .get(`${API_BASE_URL}/withdrawal/${uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        var aa = {};
        this.paidouttotal = 0;
        this.withdrawalLog = resp.data;


        


        for (var i in this.withdrawalLog) {

          if (aa[this.withdrawalLog[i].payment]) {
            console.log("empty");
          } else {
            aa[this.withdrawalLog[i].payment] = 0;
          }
          aa[this.withdrawalLog[i].payment] += parseInt(
            this.withdrawalLog[i].amount
          );
          this.paidouttotal += parseInt(this.withdrawalLog[i].amount);
          if (this.withdrawalLog[i].flag == 0) {
            this.withdrawalLog[i].flag = "Pending";
          } else if (this.withdrawalLog[i].flag == 1) {
            this.withdrawalLog[i].flag = "Completed";
          } else if (this.withdrawalLog[i].flag == 2) {
            this.withdrawalLog[i].flag = "Deleted";
          } else if (this.withdrawalLog[i].flag == 3) {
            this.withdrawalLog[i].flag = "Approved";
          }

        }


        this.gettier(aa,uid);
        this.withdrawstotal = aa;



       
      }).catch((resp) => {
        console.log("no with nemn")
      });
  },
  async gettier(withdraw,uid) {
    axios
      .get(`${API_BASE_URL}/investments/${uid}`, {
        headers: { accept: "application/json" },
      })
      .then(async (resp) => {

        var total = 0;
        var hotspotcount = 0;
        var investLog = resp.data;

        var dateCheck;
        var dateFrom = this.date5;
        var dateTo = this.date6;


        for (var i in investLog) {

          dateCheck = investLog[i].entered.slice(0, 10);


          if(dateCheck >= dateFrom && dateCheck <= dateTo){


              total += parseInt(investLog[i].amount);
              hotspotcount += parseInt(investLog[i].hotspot);

              if (investLog[i].flag == 0) {
                investLog[i].flag = "Pending";
              } else if (investLog[i].flag == 1) {
                investLog[i].flag = "Completed";
              } else if (investLog[i].flag == 2) {
                investLog[i].flag = "Deleted";
              } else if (investLog[i].flag == 3) {
                investLog[i].flag = "Approved";
              }

          }
          
          
        }

        var investname = investLog.map(({ payment }) => payment);
        var tier = {};
        var invest = {};
        var month =[];
        var currentMonthHotspot =[];
        var HNTMined =[];
        var HNTValue =[];
        var earned =[];


       
        for (var y in investLog) {

          dateCheck = investLog[i].entered.slice(0, 10);

          if(dateCheck >= dateFrom && dateCheck <= dateTo){

            invest[investLog[y].payment] = {};
            invest[investLog[y].payment].amount = investLog[y].amount;
            invest[investLog[y].payment].hotspot = investLog[y].hotspot;


            invest[investLog[y].payment].Tier1x = investLog[y].Tier1x?investLog[y].Tier1x:0;
            invest[investLog[y].payment].Tier3x = investLog[y].Tier3x?investLog[y].Tier3x:0;

            invest[investLog[y].payment].TierLiftime = investLog[y].TierLiftime?investLog[y].TierLiftime:1;
            invest[investLog[y].payment].entered = investLog[y].entered;
          }
          
        }



        for (var x in invest) {


          tier[x] = {};
          tier[x]["entered"] = moment(invest[x]["entered"]).format(
            "YYYY-MM-DD"
          );

          tier[x]["amount"] = parseInt(invest[x]["amount"]);

          tier[x]["hotspot"] = parseInt(invest[x]["hotspot"]);


          tier[x]["withdraw"] = withdraw[x];

          if (parseInt(invest[x]["amount"]) <= withdraw[x]) {
            tier[x]["tier"] = "1x";
            tier[x]["commission"] = invest[x].Tier1x;
            if (withdraw[x] <= parseInt(invest[x]["amount"]) * 4) {
              tier[x]["tier"] = "3x";
              tier[x]["commission"] = invest[x].Tier3x;
            }
            if (withdraw[x] >= parseInt(invest[x]["amount"]) * 4) {
              tier[x]["tier"] = "lifetime";
              tier[x]["commission"] = invest[x].TierLiftime;
            }
          } else {
            tier[x]["tier"] = "1x";
            
          }

          tier[x]["commission"] = invest[x].Tier1x;

          var investTable = []
          let u = await this.getMonthWiseReward(tier[x]["entered"]);
          if (u.data.length > 0) {
            let amount = 0;
            let current = 0
            let payout = 0
            
            //payout += tier[x]["withdraw"] ? tier[x]["withdraw"] : 0;

            for (let i = 0; i < u.data.length; i++) {
              current = 0
              current =
                (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                (u.data[i].amount / 100) * //this.previsiontotal total of all months amount
                tier[x]["commission"] *
                (u.data[i].HNTvalue);


                investTable.push({"month":this.months[u.data[i].month-1],"Investment Hotspot":tier[x]["hotspot"],"currentMonthHotspot":u.data[i].hotspot,"HNTMined":parseFloat(u.data[i].amount).toFixed(2),"HNTValue":u.data[i].HNTvalue,"commission":tier[x]["commission"],"earned":(current).toFixed(2)})

                
                //chk if month exist
                let traceFieldIndex = this.userInvestment.findIndex(field => {
                    return field['month'].includes(this.months[u.data[i].month-1]);
                });



               //add to existing month
               if(this.userInvestment[traceFieldIndex]){

                 /*this.userInvestment[traceFieldIndex]['currentMonthHotspot'] = this.userInvestment[traceFieldIndex]['currentMonthHotspot']+u.data[i].hotspot;

                 this.userInvestment[traceFieldIndex]['HNTMined'] = Number(this.userInvestment[traceFieldIndex]['HNTMined']).toFixed(2); + Number(u.data[i].amount).toFixed(2);*/

               }else{



                  //push filterd online hotspot
                  var results = this.currentMonthOnlineHotspot.filter(obj => {
                    return obj['month'] == this.months[u.data[i].month-1]
                  });


                  console.log("resutls = ",results)

                  

                  if(results.length){

                    this.userInvestment.push({"month":this.months[u.data[i].month-1]+"-"+results[0]['year'],"Investment Hotspot":tier[x]["hotspot"],"currentMonthHotspot":u.data[i].hotspot,"currentMonthOnlineHotspot":results[0]['online'],"HNTMined":parseFloat(u.data[i].amount).toFixed(2),"HNTValue":u.data[i].HNTvalue})
                  
                  }else{

                    //this.userInvestment.push({"month":this.months[u.data[i].month-1],"Investment Hotspot":tier[x]["hotspot"],"currentMonthHotspot":u.data[i].hotspot,"currentMonthOnlineHotspot":0,"HNTMined":parseFloat(u.data[i].amount).toFixed(2),"HNTValue":u.data[i].HNTvalue})
                  }

               }

               //sort array by month
                const sorter = (a, b) => {
                  return this.months.indexOf(a.month) - this.months.indexOf(b.month);
                };
                //this.userInvestment.sort(sorter);
                //end
               

              payout += current;
             
             
              amount = this.calctier(invest[x]["amount"], payout, tier[x]["commission"],tier, x,u.data[i].HNTvalue,invest)//remain after passing the tier
              if(amount.remainto > 0){
                payout -= amount.remainto
               
                current =
                (tier[x]["hotspot"] / u.data[i].hotspot) * //hotspotcount = sum of all months u.data[i].hotspot
                (amount.remain / 100) * //this.previsiontotal total of all months amount
                tier[x]["commission"] *
                (u.data[i].HNTvalue);
               
                investTable[investTable.length - 1]["HNTMined"]=(investTable[investTable.length - 1]["HNTMined"]-amount.remain).toFixed(2)
                
                investTable[investTable.length - 1]["earned"]=(investTable[investTable.length - 1]["earned"]-amount.remainto).toFixed(2)
               
                investTable.push({"month":(this.months[u.data[i].month-1]+" (Next Tier)"),"Investment Hotspot":tier[x]["hotspot"],"currentMonthHotspot":u.data[i].hotspot,"HNTMined":parseFloat(amount.remain).toFixed(2),"HNTValue":u.data[i].HNTvalue,"commission":tier[x]["commission"],"earned":(current).toFixed(2)})

                payout += current;
              }

            }


            tier[x]["amount"] = amount;
            tier[x]["payout"] = (
              payout - (tier[x]["withdraw"] ? tier[x]["withdraw"] : 0)
            ).toFixed(2); //subtract the withdraws
            
          }

          this.investcalc[x] = investTable;


          
        }


        this.tier = tier;

       
        

      }).catch((resp) => {
        console.log("no invest nemn")
      });
  },
  calctier(amountInvested, currentStatus, perc, tier, x,hnt,invest) {
    var remain = 0;
    var commission = 0;
    var remainto = 0
    var HNTvalue = 0
    if (currentStatus <= amountInvested && perc != invest[x].Tier3x && perc != invest[x].TierLiftime) {
      remainto = currentStatus - amountInvested
      tier[x]["tier"] = "1x";
      tier[x]["commission"] = invest[x].Tier1x;
    } 
    else if (currentStatus <= amountInvested * 4 && perc != invest[x].Tier3x && perc != invest[x].TierLiftime) {
      remainto = currentStatus - amountInvested
      HNTvalue = remainto/hnt
      tier[x]["tier"] = "3x";
      tier[x]["commission"] = invest[x].Tier3x;
      remain = HNTvalue
      
    } else if (currentStatus >= amountInvested * 4 && perc != invest[x].TierLiftime) {
      remainto = currentStatus - amountInvested* 4 
      HNTvalue = remainto/ hnt
      tier[x]["tier"] = "lifetime";
      tier[x]["commission"] = invest[x].TierLiftime;
      remain = HNTvalue
    }
    return {"remain":remain,"remainto":remainto};
  },
  getMonthWiseReward(entred) {

    return axios.get(`${API_BASE_URL}/total/reward/${entred}`, {
      headers: {
        accept: "application/json",
        "x-access-token": this.token.accessToken,
      },
    });
  },
  changeNumberFormat(value){
      if(value && !isNaN(value) && value != 0) return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return value;  
  },
  getTotalInvestment() {

     this.userInvestment = [];

     //get all uset investment data
     for (var u = 0; u < this.allUser.length; u++) {
        this.getwithdrawal(this.allUser[u].firebaseID)
     }

  },
  getNewUsers() {
    this.$root.isLoading = true;
    this.newUserProgressBar = true;
    var dateFrom = this.date7;
    var dateTo = this.date8;
    this.requestLog5 = [];
    axios.post(`${API_HELIUM_NODE_JAG_URL}/v1/new/users`, {
      'fromDate': dateFrom, 
      'toDate': dateTo,
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      this.newUserProgressBar = false;
      let newUsersArr = Object.values(response.data);
      if(newUsersArr.length > 0) {
        newUsersArr.forEach((element) => {
          let data = {};
          data.hostname = element.hostName;
          data.user =  element.hostEmail;
          data.mobile =  (element.hostPhone) ? element.hostPhone : 'N/A';
          data.signed = (element.contractSigned) ? element.contractSigned : 'N/A';
          data.createdsign = element.contractSignedDate;
          data.createDate = element.userCreationDate;
          data.name = (element.userHotspotName) ? this.formatHotspotName(element.userHotspotName) : 'N/A';
          data.address = (element.userHotspotAddress) ? element.userHotspotAddress : 'N/A';
          this.requestLog5.push(data);
        });
      }
      this.$root.isLoading = false;
    })
    .catch((error) => {
      console.log(error);
      this.$root.isLoading = false;
      this.newUserProgressBar = false;
    });
  },
  getNewUsersOld() {
    var hotspotAll=[];
    var dateCheck;
    //var dateFrom = new Date(this.date7);
    //var dateTo = new Date(this.date8);
    var dateFrom = this.date7;
    var dateTo = this.date8;
    // Added below line to solve single date filter issue
    //var dateToNew = new Date(dateTo.setDate(dateTo.getDate() + 1));
    // end
    this.requestLog5 = [];
    this.x = 0;
    var today = new Date();
    for (var u = 0; u < this.allUser.length; u++) {
      if(this.allUser[u].createDate) {

        dateCheck = moment(this.allUser[u].createDate.slice(0,10)).format("YYYY-MM-DD");


        if(dateCheck >= dateFrom && dateCheck <= dateTo) {

        console.log("dateCheck = ",dateCheck,"dateFrom = ",dateFrom,"dateTo = ",dateTo)


          axios.get(`${API_BASE_URL}/user/hotspot/${this.allUser[u].firebaseID}`, {
              headers: {
                accept: "application/json"
              },
          })
          .then((resp) => {
            hotspotAll = resp.data;
            console.log("hotspotAll = ",hotspotAll)

            if(hotspotAll.length != "0") {
              for (var i = 0; i < hotspotAll.length; i++) {
                if(hotspotAll[i].Haddress && hotspotAll[i].Haddress !="empty"){
                  axios.get(`${API_HELIUM_URL}/v1/hotspots/${hotspotAll[i].Haddress}`, {
                    headers: { accept: "application/json" },
                  })
                  .then((element) => {

                    var data = {};

                    
                    data.hostname = "";
                    data.user = "no owner";
                    data.mobile = "";

                    data.signed = "No";
                    data.createdsign = "";
                    data.createDate = "";
                    
                      
                    data.name = element.data.data.name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                      return letter.toUpperCase();
                                  });

                    data.address = element.data.data.address;



                    if(resp.data[0].role == 0){
                      data.relationship="Primary Owner";
                    }else if(resp.data[0].role ==1){
                      data.relationship="Referral Owner";
                    }else{
                      data.relationship="Watcher"
                    }
                        

                    //chk if duplicate exists
                    let traceFieldIndex = this.requestLog5.findIndex(field => {
                        return field['address'].includes(element.data.data.address);
                    });

                    //chk if duplicate exists
                    if(this.requestLog5[traceFieldIndex]){
                      console.log("empty records")
                    }else{

                        this.getUserHotspot(element.data.data.address, this.x);
                        this.requestLog5.push(data);

                        this.x += 1;
                    }

                        

                  })
                  .catch((resp) => {
                    console.log("error")
                  });
                }
              }
            }
          })
          .catch((resp) => {
            console.log("db error")
          });
        }
      }
    }
  },
  customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
          console.log("index = ",index,"items = ",items,"isDesc = ",isDesc,"a = ",a,"b = ",b)
          if (index[0]=='month') {
            
              if (!isDesc[0]) {
                  return (a['currentMonthHotspot'] < b['currentMonthHotspot']) ? -1 : 1;
              } else {
                  return (b['currentMonthHotspot'] < a['currentMonthHotspot']) ? -1 : 1;
              }
          }
          else {
            if(typeof a[index] !== 'undefined'){
               if (!isDesc[0]) {
               console.log("empty1")
                  return (a[index[0]] < b[index[0]]) ? -1 : 1;
              } else {
              console.log("empty2")
                  return (b[index[0]] < a[index[0]]) ? -1 : 1;
              }
            }else{
               console.log("empty3")
            }
          }
      });
      return items;
  },
  accountInfo() {

      this.hotspotaccount = [];
      let hotspot = [];

      this.accountAdded.forEach((element) => {
        
        hotspot.push(
          axios.get(
            `${API_HELIUM_URL}/v1/accounts/${element.address}/hotspots`,
            { headers: { accept: "application/json" } }
          )
        );
        
      });

      Promise.all(hotspot).then((results) => {
        
          results.forEach((resp) => {

            if(resp.data.data.length){
              this.hotspotaccount = resp.data.data.concat(this.hotspotaccount);
            }

          });

          this.jagMiningReportAPI = true;

      }).catch((resp) => {
          this.jagMiningReportAPI = false;
      });
     
    },
    filterOnlineHotspot() {
      this.$root.isLoading = true;
      this.currentMonthOnlineHotspot = [];
      this.calHotspotMonthWise(0);
    },
    calHotspotMonthWise(ind){

      var dateCheck;
      var today = new Date();
      var dateFrom = this.date5;
      var dateTo = this.date6;


      if(ind<this.hotspotaccount.length) { 
        
         dateCheck = this.hotspotaccount[ind].timestamp_added?this.hotspotaccount[ind].timestamp_added.slice(0,10):dateTo;

         var month = dateCheck.split("-")[1];
         var year = dateCheck.split("-")[0];


          if(this.hotspotaccount[ind].status){


               //chk if month exist
                let traceFieldIndex = this.currentMonthOnlineHotspot.findIndex(field => {
                    return field['month'].includes(this.months[month-1]) && field['year'].includes(year);
                });
                
               //add to existing month
               if(this.currentMonthOnlineHotspot[traceFieldIndex]){
                 
                   this.currentMonthOnlineHotspot[traceFieldIndex]['online'] = parseInt(this.currentMonthOnlineHotspot[traceFieldIndex]['online']) + 1;
                 
               }else{
                 this.currentMonthOnlineHotspot.push({"month":this.months[month-1],"online":1,"year":year})
               }

          }


        ind++;
        this.calHotspotMonthWise(ind);
        
      }else{

          //sort array by month
          const sorter = (a, b) => {
            
            let ad = new Date(a.month + ' 1,' + a.year),
              bd = new Date(b.month + ' 1,' + b.year);
              return ad.getTime() - bd.getTime();
            };
            this.currentMonthOnlineHotspot.sort(sorter);
          //end

          
          for(let i = 0;i<this.currentMonthOnlineHotspot.length;i++){

             
             if(i != 0){

                this.currentMonthOnlineHotspot[i]['online'] = parseInt(this.currentMonthOnlineHotspot[i]['online'])+parseInt(this.currentMonthOnlineHotspot[i-1]['online']);
               
             }


             console.log("this.currentMonthOnlineHotspot = ",this.currentMonthOnlineHotspot[i]['online'],"mon = ",this.currentMonthOnlineHotspot[i]['month'],"year = ",this.currentMonthOnlineHotspot[i]['year'])
            
          }


          console.log("this.currentMonthOnlineHotspot = ",this.currentMonthOnlineHotspot)

          //get report data from the database
          this.getJagMiningReport()

      }
      this.$root.isLoading = false;
    },

    getJagMiningReport(){
      
      this.totalJagMiningReport = [];

      axios
      .get(`${API_BASE_URL}/total/reward/${this.date5}/${this.date6}`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {

        
        var data = {};

        console.log("Mining Data = ",resp.data)

        resp.data.forEach((element, index) => {

          console.log("element = ",element)
          data = {};

          data.month = this.months[element.month-1] +" - "+element.year;
          data.currentMonthHotspot = 0;
          data.currentMonthOnlineHotspot = element.hotspot;

          data.HNTMined = Number(element.amount).toFixed(2);

          data.HNTValue = Number(element.HNTvalue).toFixed(2);


          console.log("this.currentMonthOnlineHotspot = ",this.currentMonthOnlineHotspot)

          // let currMonthonline = this.currentMonthOnlineHotspot.filter(obj => {
          //     return obj['month'] == this.months[element.month-1] && obj['year'] == element.year;
          // });

          // console.log("currMonthonline = ",currMonthonline.length);

          // data.currentMonthHotspot = currMonthonline.length?currMonthonline[0]['online']:this.totalJagMiningReport[this.totalJagMiningReport.length-1]['currentMonthHotspot'];

          this.totalJagMiningReport.push(data);

        })


      }).catch((resp) => {
        
      });
    },
    getWalletSummary(){
        this.$root.isLoading = true;
        this.requestLog7 = [];

        var dateFrom = new Date(this.date9);
        var dateTo = new Date(this.date10);

        
        axios
        .get(`${API_BASE_URL}/walletSummary/${this.date9}/${this.date10}`,{
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        } )
        .then((resp) => {
          let walletSummaryData = resp.data;
          if(walletSummaryData.length > 0) {

            //default sorting
            walletSummaryData.sort(function(a,b){
              return new Date(a.Date) - new Date(b.Date);
            });

            walletSummaryData.forEach((element, index) => {
              let dataObj = {};
              dataObj.Date = this.changeDateFormat(element.Date);
              dataObj.HNT = Number(element.HNT).toFixed(3);
              dataObj.HNTClosingPrice = element.HNTClosingPrice;
              dataObj.ONLINE = this.changeNumberFormat(element.ONLINE);
              dataObj.USD = this.changeNumberFormat(Number(element.USD * element.HNT).toFixed(2));
              this.requestLog7.push(dataObj);
            });

          }
          this.$root.isLoading = false;

        });
        
    },
    downloadCSVWalletSummary(){

      console.log("this.requestLog7 = ",this.requestLog7);

      let csv = 'Date,HNT,HNT Closing Price,USD, Online Hotspots\n';
      this.requestLog7.forEach((row) => {
              console.log(row)
              csv += row.Date+",";
              csv += row.HNT+",";
              csv += row.HNTClosingPrice+",";
              csv += row.USD+",";
              csv += row.ONLINE+",";
              csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'walletSummary.csv';
      anchor.click();
    },
    changeDateFormat(date){
      return moment(date).format('MM-DD-YYYY');
    }
  }
};
</script>