export const API_BASE_URL = 'https://supp-api.jag.network';
export const API_HELIUM_URL = 'https://sharkfed.api.stakejoy.com';
export const API_HELIUM_NODE_JAG_URL = 'https://helium-node.jag.network';
export const FIRE_BASE = {
    apiKey: "AIzaSyDhBin0Dq89xUhLmFWhuI7mxXrl6RxaF9o",
    authDomain: "jagindustrials1.firebaseapp.com",
    databaseURL: "https://jagindustrials1.firebaseio.com",
    projectId: "jagindustrials1",
    storageBucket: "",
    messagingSenderId: "",
    appId: ""
}